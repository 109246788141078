/*eslint camelcase: ["error", {allow: [""]}]*/

import { AnalyticsUserActionEventObjectException } from '../errors/analyticsError';
import Logger from '../utils/analyticsLogger';
import DataObject from './DataObject';

export default class UserActionEventObject extends DataObject {
  constructor(data) {
    if (data.eventtype) {
      super(data.eventype, data);
    } else {
      super('uaevent', data);
    }
    if (!data.category) {
      Logger.error(
        "[AnalyticsUserActionEventObjectException] Category is required for uaevent (L'Oréal guideline)",
        data
      );
      throw new AnalyticsUserActionEventObjectException(
        "Category is required for uaevent (L'Oréal guideline)"
      );
    }

    if (!data.action) {
      Logger.error(
        "[AnalyticsUserActionEventObjectException] Action is required for uaevent (L'Oréal guideline)",
        data
      );
      throw new AnalyticsUserActionEventObjectException(
        "Action is required for uaevent (L'Oréal guideline)"
      );
    }

    if (!data.label) {
      Logger.error(
        "[AnalyticsUserActionEventObjectException] Label is required for uaevent (L'Oréal guideline)",
        data
      );
      throw new AnalyticsUserActionEventObjectException(
        "Label is required for uaevent (L'Oréal guideline)"
      );
    }

    this.result.ecommerce = undefined;
  }

  set category(category) {
    if (category != 'Ecommerce') {
      this.result.eventCategory = this.setProperty(category);
    } else {
      this.result.eventCategory = 'Ecommerce';
    }
  }

  get category() {
    return this.result.eventCategory;
  }

  set action(action) {
    this.result.eventAction = this.setProperty(action);
  }

  get action() {
    return this.result.eventAction;
  }

  set event_name(event_name) {
    this.result.event_name = this.setProperty(event_name);
  }

  get event_name() {
    return this.result.event_name;
  }

  set referral(referral) {
    this.result.referral = this.setProperty(referral);
  }

  get referral() {
    return this.result.referral;
  }

  set article_name(value) {
    this.result.article_name = this.setProperty(value);
  }

  get article_name() {
    return this.result.article_name;
  }

  set module_name(value) {
    this.result.module_name = this.setProperty(value);
  }

  get module_name() {
    return this.result.module_name;
  }

  set service_name(service_name) {
    this.result.service_name = this.setProperty(service_name);
  }

  get service_name() {
    return this.result.service_name;
  }

  set service_category(value) {
    this.result.service_category = this.setProperty(value);
  }

  get service_category() {
    return this.result.service_category;
  }

  // uses only for VTO v1
  // should be removed after moving to VTO v2, DSF app
  set service_version(value) {
    this.result.service_version = this.setProperty(value);
  }

  get service_version() {
    return this.result.service_version;
  }

  set cta_name(cta_name) {
    this.result.cta_name = this.setProperty(cta_name);
  }

  get cta_name() {
    return this.result.cta_name;
  }

  set link_url(link_url) {
    this.result.link_url = this.setProperty(link_url);
  }

  get link_url() {
    return this.result.link_url;
  }

  set click_area(click_area) {
    this.result.click_area = this.setProperty(click_area);
  }

  get click_area() {
    return this.result.click_area;
  }

  set video_title(video_title) {
    this.result.video_title = this.setProperty(video_title);
  }

  get video_title() {
    return this.result.video_title;
  }

  set video_action(video_action) {
    this.result.video_action = this.setProperty(video_action);
  }

  get video_action() {
    return this.result.video_action;
  }

  set breadcrumb(value) {
    this.result.breadcrumb = this.setProperty(value);
  }

  get breadcrumb() {
    return this.result.breadcrumb;
  }

  set label(label) {
    this.result.eventLabel = this.setProperty(label);
  }

  get label() {
    return this.result.eventLabel;
  }
  set requestType(value) {
    this.result.requestType = this.setProperty(value);
  }

  get requestType() {
    return this.result.requestType;
  }

  set value(value) {
    this.result.eventValue = this.setProperty(value);
  }

  set detail(event_detail) {
    this.result.event_detail = this.setProperty(event_detail);
  }

  get detail() {
    return this.result.event_detail;
  }

  set product_info(product_info) {
    this.result.product_info = this.setProperty(product_info);
  }

  get product_info() {
    return this.result.product_info;
  }

  set product_tab(product_tab) {
    this.result.product_tab = this.setProperty(product_tab);
  }

  get product_tab() {
    return this.result.product_tab;
  }

  set step(step) {
    this.result.step = this.setProperty(step);
  }

  get step() {
    return this.result.step;
  }

  set filter_type(filter_type) {
    this.result.filter_type = this.setProperty(filter_type);
  }

  get filter_type() {
    return this.result.filter_type;
  }

  set filter_value(filter_value) {
    this.result.filter_value = this.setProperty(filter_value);
  }

  get filter_value() {
    return this.result.filter_value;
  }

  set social_network(social_network) {
    this.result.social_network = this.setProperty(social_network);
  }

  get social_network() {
    return this.result.social_network;
  }

  set social_action(social_action) {
    this.result.social_action = this.setProperty(social_action);
  }

  get social_action() {
    return this.result.social_action;
  }

  set event_detail(event_detail) {
    this.result.event_detail = this.setProperty(event_detail);
  }

  get event_detail() {
    return this.result.event_detail;
  }

  set productInfo(product_info) {
    this.result.product_info = this.setProperty(product_info);
  }

  get productInfo() {
    return this.result.product_info;
  }

  set productTab(product_tab) {
    this.result.product_tab = this.setProperty(product_tab);
  }

  get productTab() {
    return this.result.product_tab;
  }

  set position(position) {
    this.result.position = this.setProperty(position);
  }

  get position() {
    return this.result.position;
  }

  set click_action(click_action) {
    this.result.click_action = this.setProperty(click_action);
  }

  get click_action() {
    return this.result.click_action;
  }
}
