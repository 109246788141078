<template>
  <transition name="modal">
    <div
      v-show="show"
      :class="[
        'oap-beauty-genius-wrapper',
        `oap-beauty-genius-wrapper--${type}`,
        { '-has-close-bar': hasCloseBar },
        position,
      ]"
    >
      <button v-if="hasCloseBar" class="oap-beauty-genius-wrapper__close-wrapper" @click="close">
        <svg class="oap-beauty-genius-wrapper__close-icon" aria-hidden="true">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
        </svg>
      </button>

      <template v-if="showIframeFirstTime">
        <div v-show="showIframe" class="oap-beauty-genius-wrapper__iframe">
          <div class="oap-beauty-genius-wrapper__overlay" @click="close"></div>
          <iframe
            ref="iframe"
            :title="iframeTitle"
            :src="iframeUrl"
            :allow="iframeAllow"
            class="oap-iframe is-overflow-hidden"
          >
          </iframe>
        </div>
      </template>

      <template v-if="isSlot">
        <slot></slot>
      </template>
    </div>
  </transition>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from 'vue';
import { AnalyticsHandler, urlHasKey } from '@Foundation';
import eventBus from '@loreal/eventbus-js';
import {
  BEAUTY_GENIUS_NAME_HIDE,
  BEAUTY_GENIUS_NAME_SHOW,
  BEAUTY_GENIUS_NAME_ASK_QUESTION,
  BEAUTY_GENIUS_TYPE_IN_TAGGING_EVENT,
} from '@Feature/OapBeautyGeniusWrapper/code/Scripts/settings';

const props = defineProps({
  type: { type: String, required: true },

  analytics: {
    type: Object,
    default: () => {},
  },
  hasCloseBar: { type: Boolean, default: true },
  openServiceUrlParam: { type: String, default: 'bg-app' },

  iframeBaseUrl: { type: String, default: 'https://vbc.beauty.tech/' },
  iframeDomainUrl: { type: String, required: true },
  iframeDomainUrlParameter: { type: String, default: 'parentDomain' },
  iframeTitle: { type: String, default: 'Beauty Genius' },
  iframeAllow: { type: String, default: 'camera; microphone' },
  iframePosition: { type: String, default: 'right' },

  iframeOpenEventName: { type: String, default: 'beauty-genius-iframe:open' },
  iframeCloseEventName: { type: String, default: 'beauty-genius-iframe:close' },
  iframeWindowEventName: { type: String, default: 'message' },
  iframeWindowCloseEventType: { type: String, default: 'command' },

  modalTopOpenEventName: { type: String, default: 'beauty-genius-modal-top:open' },
  modalOpenEventName: { type: String, default: 'beauty-genius-modal:open' },
  modalCloseEventName: { type: String, default: 'beauty-genius-modal:close' },

  logoAnimationStartEventName: { type: String, default: 'beauty-genius-logo-animation:start' },
  logoAnimationStopEventName: { type: String, default: 'beauty-genius-logo-animation:stop' },

  bannerCookieName: { type: String, default: 'oap-beauty-genius-banner' },
  bannerCookieValue: { type: Number, default: 1 },
  bannerCookiePath: { type: String, default: '/' },
  bannerCookieExpiration: { type: String, default: '' },
  bannerVisibilityDelay: { type: Number, default: 10 },
});

const isBanner = computed(() => props.type === 'banner');
const isIframe = computed(() => props.type === 'iframe');
const isModalTop = computed(() => props.type === 'modal-top');

const isSlot = computed(
  () => props.type === 'banner' || props.type === 'modal' || props.type === 'modal-top'
);

const isNonBannerModal = computed(() => isSlot.value && !isBanner.value);
const isBannerModal = computed(() => isSlot.value && isBanner.value);

const position = computed(() => {
  return isIframe.value ? `oap-beauty-genius-wrapper--position-${props.iframePosition}` : '';
});

const parentDomainPair = `${props.iframeDomainUrlParameter}=${props.iframeDomainUrl}`;
const iframeUrl = ref(`${props.iframeBaseUrl}?${parentDomainPair}`);

const show = ref(false);
const showIframeFirstTime = ref(false);
const showIframe = ref(false);

const isIframeLoaded = ref(false);

const noScrollCustomClass = '-no-scroll-custom';

function pushAnalytics(type) {
  if (props.analytics) {
    const tag = { ...props.analytics };
    tag.action = props.analytics[type].action;
    tag.category = props.analytics[type].category;
    tag.event_name = props.analytics[type].event_name;
    tag.label = props.analytics[type].label;

    if (props.analytics[type].service_category) {
      tag.service_category = props.analytics[type].service_category;
    }

    try {
      AnalyticsHandler.getAnalyticsHandler().push(tag);
    } catch (e) {
      /* istanbul ignore next */
      console.warn('Could not push to dataLayer', e);
    }
  }
}

function open(event) {
  if (isIframe.value) {
    window.addEventListener(props.iframeWindowEventName, (event) => {
      if (event.data.type === props.iframeWindowCloseEventType) {
        close();
      }

      if (event.data.type === 'event') {
        window.dataLayer.push(event.data.data);
      }
    });

    let interval = false;
    let intervalCounter = 0;

    interval = setInterval(() => {
      ++intervalCounter;
      if (intervalCounter > 50) return;

      if (iframe.value) {
        if (!isIframeLoaded.value) {
          iframe.value.onload = () => {
            isIframeLoaded.value = true;

            sendMessagesToIframe(event);
          };
        } else {
          sendMessagesToIframe(event);
        }

        clearInterval(interval);
      }
    }, 200);

    showIframeFirstTime.value = true;
    showIframe.value = true;
    document.querySelector('body').classList.add(noScrollCustomClass);
  }

  show.value = true;
  eventBus.emit(props.logoAnimationStopEventName);
  pushAnalytics('open');
}

function sendMessagesToIframe(event) {
  sendIframePostMessage({
    name: BEAUTY_GENIUS_NAME_SHOW,
  });

  const prompt = event && event.payload ? event.payload : null;

  if (prompt) {
    sendIframePostMessage({
      name: BEAUTY_GENIUS_NAME_ASK_QUESTION,
      prompt,
    });
  }

  if (window.Optanon) {
    sendIframePostMessage({
      name: 'optanonConsentId',
      id: window.Optanon.getDataSubjectId(),
    });

    if (!optanonGroups) {
      optanonGroups = checkPermissionGroups();
    }

    sendIframePostMessage({
      name: 'optanonGroups',
      groups: optanonGroups,
    });
  }
}

function close() {
  if (isNonBannerModal.value) {
    eventBus.emit(props.modalCloseEventName);
  }

  if (isIframe.value) {
    sendIframePostMessage({
      name: BEAUTY_GENIUS_NAME_HIDE,
    });

    showIframe.value = false;
    eventBus.emit(props.iframeCloseEventName);
    document.querySelector('body').classList.remove(noScrollCustomClass);
  }

  show.value = false;
  eventBus.emit(props.logoAnimationStartEventName);
  pushAnalytics('close');
}

function openBannerOnCookieAbsence() {
  if (!document.cookie.includes(props.bannerCookieName)) {
    open();
    eventBus.on(props.iframeOpenEventName, close);
    document.cookie = `${props.bannerCookieName}=${props.bannerCookieValue}${props.bannerCookieExpiration}; path=${props.bannerCookiePath}`;
  }
}

let iframe = ref(null);

function sendIframePostMessage(messageObject) {
  iframe.value.contentWindow.postMessage(
    {
      ...messageObject,
    },
    props.iframeBaseUrl
  );
}

let optanonGroups;

function getCookie(name) {
  let cookie = {};

  document.cookie.split(';').forEach(function (el) {
    let split = el.split('=');
    cookie[split[0].trim()] = split.slice(1).join('=');
  });

  return cookie[name];
}

function extractNonZeroValues(str) {
  return str
    .split(',')
    .filter((pair) => pair.split(':')[1] !== '0')
    .map((pair) => pair.split(':')[0]);
}

function checkPermissionGroups() {
  const cookieValue = getCookie('OptanonConsent');
  const decodedcookieValue = decodeURIComponent(cookieValue);
  const keyValuePairs = decodedcookieValue.split('&');
  const groupsString = 'groups=';

  return extractNonZeroValues(
    keyValuePairs.find((pair) => pair.startsWith(groupsString)).slice(groupsString.length)
  );
}

function optanonGroupsUpdateListener(eventName) {
  window.addEventListener(eventName, (event) => {
    sendIframePostMessage({
      name: 'optanonGroups',
      groups: event.detail,
    });
  });
}

onMounted(() => {
  optanonGroupsUpdateListener('OneTrustGroupsUpdated');

  if (isBannerModal.value) {
    setTimeout(openBannerOnCookieAbsence, props.bannerVisibilityDelay * 1000);
  }

  if (isNonBannerModal.value) {
    eventBus.on(props.iframeOpenEventName, close);

    if (isModalTop.value) {
      eventBus.on(props.modalTopOpenEventName, open);
    } else {
      // For all the other modals (non banner and non modal top - so called transitional banner)
      eventBus.on(props.modalOpenEventName, (event) => {
        open();

        nextTick(() => {
          if (event) {
            eventBus.emit(BEAUTY_GENIUS_TYPE_IN_TAGGING_EVENT, event);
          }
        });
      });
    }
  }

  if (isIframe.value) {
    eventBus.on(props.iframeOpenEventName, (event) => {
      event ? open(event) : open();
    });

    if (urlHasKey(props.openServiceUrlParam)) {
      open();
    }
  }
});
</script>
