<template>
  <button
    class="oap-button -primary mikmak-button"
    :class="cssClass"
    :data-mm-sticky="sticky"
    :data-mm-wtbid="wtbId"
    :data-mm-ids="product.id"
    @click="pushTaggingEvent"
  >
    <span class="oap-button__label">{{ label }}</span>
  </button>
</template>

<script>
import { AnalyticsHandler } from '@Foundation';

export default {
  name: 'OapMikMak',
  props: {
    wtbId: { type: String, required: true },
    product: { type: Object, required: true },
    label: { type: String, required: false, default: 'Buy Now' },
    sticky: { type: Boolean, default: false },
    isPdp: { type: Boolean, default: false },
    cssClass: { type: String, default: '' },
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const buyParam = urlParams.get('buy');
    this.checkIfMikMakIsLoaded(() => {
      window.swnDataLayer.widget_callback = this.handleMikMakEvents;
      if (window.aweLoader.isOpen()) return;
      if (buyParam == 'true' && this.isPdp) {
        document.querySelector('.mikmak-button:not(.-fluid)').click();
      }
    });
  },
  methods: {
    checkIfMikMakIsLoaded(method) {
      let counter = 0;
      const maxChecks = 10;
      return new Promise((resolve, reject) => {
        const checkInterval = setInterval(() => {
          if (window.aweLoader) {
            clearInterval(checkInterval);
            method();
            resolve();
          } else if (counter >= maxChecks) {
            clearInterval(checkInterval);
            reject(new Error('Maximum number of checks reached'));
          }
          counter++;
        }, 1000);
      });
    },

    pushTaggingEvent() {
      const productInfo = `${this.product.name}::${this.product.id}`;
      AnalyticsHandler.getAnalyticsHandler().push({
        type: 'userActionEvent',
        event_name: 'buy_now',
        action: 'submit',
        category: 'Ecommerce',
        ecommerce: 'undefined',
        label: productInfo,
        product_info: productInfo,
      });
    },

    handleMikMakEvents(event) {
      if (event.action == 'buy') {
        const productInfo = `${event.product.name}::${event.product.ean}`;
        const storeName = event.store.name;
        const currency = event.product.currency;
        const currentUrl = new URL(window.location.href);
        const dimension49 = this.getDimension49();
        const dimension91 = currentUrl.searchParams.get('prescripted') || 'none';

        AnalyticsHandler.getAnalyticsHandler().push({
          type: 'addToCart',
          event_name: 'add_to_cart',
          category: 'ecommerce',
          action: `redirection::${storeName}`,
          label: productInfo,
          productInfo: productInfo,
          step: 'redirection',
          store_name: storeName,
          ecommerce: {
            currencyCode: currency,
            add: {
              products: [
                {
                  ...this.product,
                  dimension49: dimension49,
                  dimension91: dimension91,
                },
              ],
            },
          },
        });
      }
    },

    getDimension49() {
      const currentUrl = new URL(window.location.href);
      const initialProductDimension49 = this.product.dimension49;
      const urlProductDimension49 = currentUrl.searchParams.get('utm_medium') === 'vto';
      const windowDimension49 = window.vtoActivated;

      if (urlProductDimension49 || windowDimension49) {
        return 'activated';
      }

      return initialProductDimension49;
    },
  },
};
</script>
