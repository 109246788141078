import VideoPlaceholder from './code/Scripts/components/video-placeholder.vue';
import VideoPlaceholderFullpage from './code/Scripts/components/video-placeholder-fullpage.vue';
import VideoPlaceholderWrapper from './code/Scripts/components/video-placeholder-wrapper.vue';
import VideoPlaceholderTiktok from './code/Scripts/components/video-placeholder-tiktok.vue';
import VideoPlaceholderTranscript from './code/Scripts/components/video-placeholder-transcript.vue';
import OapVideoPopin from './code/Scripts/components/OapVideoPopin.vue';

export default {
  components: {
    VideoPlaceholder,
    VideoPlaceholderFullpage,
    VideoPlaceholderTiktok,
    VideoPlaceholderWrapper,
    VideoPlaceholderTranscript,
    OapVideoPopin,
  },
};
