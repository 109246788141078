<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="footerNewsletterSignUpModal">
    <modal :modalidentifier="modalId">
      <div class="footerNewsletterSignUpModal__header">
        <p class="footerNewsletterSignUpModal__title">{{ modalTitle }}</p>
      </div>
      <div v-if="!modalLoaded" class="footerNewsletterSignUpModal__loading"><OapLoader /></div>
      <div v-if="isSubmited && modalLoaded" class="footerNewsletterSignUpModal__feedback">
        <div class="footerNewsletterSignUpModal__feedback-text" v-html="feedbackContent"></div>
        <a :href="feedbackBtnLink" class="button -secondary">{{ feedbackBtn }}</a>
      </div>
      <div v-else ref="modalGf" @click="onModalGfLoad" @keyup.enter="onModalGfLoad">
        <slot v-if="modalLoaded" name="generic-form"></slot>
      </div>
    </modal>
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';
import modal from '../../../../Modal/code/Scripts/components/modal.vue';
import OapLoader from '../../../../OapLoader/code/Scripts/components/OapLoader.vue';

export default {
  name: 'FooterNewsletterSignupModal',
  components: {
    modal,
    OapLoader,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    feedbackTitle: {
      type: String,
      default: '',
    },
    feedbackContent: {
      type: String,
      default: '',
    },
    feedbackBtn: {
      type: String,
      default: '',
    },
    feedbackBtnLink: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      modalId: 'footerNewsletterSignUp',
      isSubmited: false,
      modalLoaded: false,
      firstNameVal: '',
      lastNameVal: '',
      emailVal: '',
    };
  },
  computed: {
    modalTitle() {
      return this.isSubmited ? this.feedbackTitle : this.title;
    },
  },
  mounted() {
    this.onFooterGFSubmitted();
    eventBus.on('modal:closed', (modalId) => {
      if (this.modalId === modalId) {
        this.resetFooterForm();
      }
    });
  },
  destroyed() {
    document.removeEventListener('click');
  },
  methods: {
    openModal() {
      eventBus.on('form:success', () => {
        eventBus.emit('modal:footerNewsletterSignUp');
        this.getFilledInfo();
        setTimeout(() => {
          this.modalLoaded = true;
        }, 800);
      });
    },
    onFooterGFSubmitted() {
      let footerFormBtn = document.querySelector('.--footerNewsletterSignUp .generic-form__submit');
      if (footerFormBtn) {
        footerFormBtn.addEventListener('click', () => {
          this.openModal();
        });
      }
    },
    onModalGfLoad() {
      this.setHiddenFieldsVals();
      eventBus.on('form:success', () => {
        this.modalLoaded = false;
        setTimeout(() => {
          this.isSubmited = true;
        }, 800);
      });
    },
    resetFooterForm() {
      setTimeout(() => {
        location.reload();
      }, 100);
    },
    getFilledInfo() {
      let footerForm = document.querySelector('.--footerNewsletterSignUp');
      let firstNVal = footerForm.querySelector('.fisrtnameInput input');
      let lastNVal = footerForm.querySelector('.lastnameInput input');
      let emailVal = footerForm.querySelector('.emailInput input');
      if (firstNVal && lastNVal && emailVal) {
        this.firstNameVal = firstNVal.value;
        this.lastNameVal = lastNVal.value;
        this.emailVal = emailVal.value;
      }
    },
    setHiddenFieldsVals() {
      let modalForm = document.querySelector('.--footerNewsletterSignUpModal');
      let firstN = modalForm.querySelector('.fisrtnameInput input');
      let lastN = modalForm.querySelector('.lastnameInput input');
      let email = modalForm.querySelector('.emailInput input');

      if (firstN && lastN && email) {
        firstN.value = this.firstNameVal;
        lastN.value = this.lastNameVal;
        email.value = this.emailVal;

        let data = { firstName: firstN, lastName: lastN, email: email };
        eventBus.$emit('setHiddenFieldsVals', data);
      }
    },
  },
};
</script>
