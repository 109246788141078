<template>
  <div :id="id" ref="root" class="pop-in" @keydown.esc="closePopIn" @click="closePopIn">
    <div id="pop-in-card-content" class="pop-in-content">
      <button class="close" @click="closePopIn"></button>
      <div v-if="iframeOpened">
        <slot name="popup-content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
// TODO Reduce the amount of DOM queries by caching elements into variables
const OPENED_CLASS = 'opened';

export default {
  name: 'OapCardPopin',
  props: {
    id: {
      type: String,
      default: 'pop-in-card',
    },
  },
  data() {
    return {
      iframeOpened: false,
    };
  },
  mounted() {
    document.addEventListener('keyup', (evt) => {
      if (evt.keyCode === 27 && this.$refs.root.classList.contains(OPENED_CLASS)) {
        this.closePopIn();
      }
    });

    const triggers = document.querySelectorAll('.newsletter-i-frame-link');

    if (triggers.length) {
      triggers.forEach((item) => {
        item.addEventListener('click', () => {
          if (item?.id === this.$refs.root.id) {
            this.$refs.root.classList.add(OPENED_CLASS);
            document.body.classList.add('noScroll');
            this.iframeOpened = true;
          }
        });
      });
    }
  },
  beforeUnmount() {
    if (this.$refs.root.classList.contains(OPENED_CLASS)) {
      this.closePopIn();
    }
  },
  methods: {
    closePopIn() {
      this.$refs.root.classList.remove(OPENED_CLASS);
      document.body.classList.remove('noScroll');
    },
  },
};
</script>

<style>
.pop-in {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  background: rgb(0 0 0 / 70%);
  visibility: hidden;
  text-align: center;
}

.pop-in.opened {
  visibility: visible;
  opacity: 1;
  width: 100%;
}

.pop-in-content {
  max-width: 630px;
  width: 100%;
}

.pop-in .pop-in-content {
  text-align: left;
  position: relative;
  margin: 66px auto;
  padding: 16px;
  background: #fff;
  border-radius: 4px;
  display: inline-block;
  color: black;
  height: calc(100% - 132px);
  max-height: 745px;
}

.pop-in .pop-in-content .close {
  display: block;
  position: relative;
  top: 2px;
  right: 0;
  float: right;
  width: 30px;
  height: 30px;
  z-index: 999;
  padding: 0;
  border: 0;
  cursor: pointer;
  margin-bottom: 10px;
}

.pop-in .pop-in-content .close::before,
.pop-in .pop-in-content .close::after {
  position: absolute;
  z-index: 100000;
  left: 13px;
  top: 0;
  content: "";
  height: 25px;
  width: 2px;
  background-color: black;
}

.pop-in .pop-in-content .close::before {
  transform: rotate(45deg);
}

.pop-in .pop-in-content .close::after {
  transform: rotate(-45deg);
}

.popin-container-content .btn-default {
  background: #242424 !important;
  color: #fff !important;
  text-transform: uppercase;
  font-size: 18px;
  font-family: "DINNextW01-CondensedBol", serif;
  letter-spacing: 0.3px;
  border-radius: 0;
}

.popin-container-content .btn-default:active:focus {
  background: #242424;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  font-family: "DINNextW01-CondensedBol", serif;
  letter-spacing: 0.3px;
  border-radius: 0;
}

#pop-in-card-content iframe {
  margin-top: 30px;
  height: 89%;
  position: absolute;
  width: calc(100% - 30px);
}

@media only screen and (max-width: 600px) {
  .pop-in .pop-in-content {
    margin: 0 auto;
    height: 100vh;
    max-height: 100%;
  }

  #pop-in-card-content iframe {
    height: calc(100vh - 60px);
  }
}
</style>
