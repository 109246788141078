<template>
  <div class="videoComponent">
    <div class="embedResponsive embedResponsive--16by9 embedResponsive--rounded-borders">
      <iframe height="300" width="400" :title="iframeTitle" :src="url"></iframe>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  videoId: { type: String, required: true },
  iframeTitle: { type: String, default: 'tiktok-video-title' },
  baseUrl: { type: String, default: 'https://www.tiktok.com/player/v1/' },
  defaultSettings: { type: String, default: 'timestamp=0&fullscreen_button=0' },
  autoplay: { type: Boolean, default: false },
});

const autoplaySetting = props.autoplay ? '&autoplay=1' : '';
const url = computed(
  () => `${props.baseUrl}${props.videoId}?${props.defaultSettings}${autoplaySetting}`
);
</script>
