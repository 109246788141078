<template>
  <form
    :id="formId"
    ref="form"
    :action="formAction"
    :class="['generic-form', cssClassForm]"
    :name="formId"
    novalidate
    @submit.prevent="submitEvent"
  >
    <!-- Header -->
    <slot v-if="$slots.header" name="header"></slot>

    <!-- Row -->
    <div class="generic-form__row">
      <!-- Sections / Columns -->
      <section
        v-for="(section, index) in validatedSections"
        v-show="!section.hidden"
        :key="index"
        class="generic-form__section"
      >
        <!-- Section Title -->
        <component :is="section.titleTag" v-if="section.title" class="generic-form__section-title">
          {{ section.title }}
        </component>

        <!-- Fields -->
        <div
          v-for="(field, fIndex) in section.fields"
          :key="fIndex"
          :class="field.cssClass"
          class="generic-form__field"
        >
          <component
            :is="typeMap[field.type]"
            v-model="formData[field.id]"
            v-bind="field"
            :readonly="field.readOnly"
            @reset-errors="resetErrors(field)"
          >
            <template v-if="field.label">
              {{ field.label }}
            </template>
            <template v-if="field.helpText" #helperText>
              <div class="generic-form__helper-text" v-html="field.helpText"></div>
            </template>
          </component>
        </div>

        <!-- Subsections -->
        <div class="generic-form__subsection">
          <div
            v-for="(subSection, sIndex) in section.subSections"
            :key="sIndex"
            class="generic-form__wrapper"
            :class="subSection.cssClass"
          >
            <component
              :is="subSection.titleTag"
              v-if="subSection.title"
              class="generic-form__wrapper__title"
            >
              {{ subSection.title }}
            </component>

            <p v-if="subSection.description" class="generic-form__wrapper__description">
              {{ subSection.description }}
            </p>
            <div
              v-for="(subSectionField, sFIndex) in subSection.fields"
              :key="sFIndex"
              :class="subSectionField.cssClass"
              class="generic-form__field"
            >
              <component
                :is="typeMap[subSectionField.type]"
                :model-value="formData[subSectionField.id]"
                :readonly="subSectionField.readOnly"
                v-bind="subSectionField"
                @update:modelValue="(newValue) => (formData[subSectionField.id] = newValue)"
                @reset-errors="resetErrors(subSectionField)"
              >
                <template v-if="subSectionField.label">
                  {{ subSectionField.label }}
                </template>
                <template v-if="subSectionField.helpText" #helperText>
                  <div class="generic-form__helper-text" v-html="subSectionField.helpText"></div>
                </template>
              </component>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- Footer -->
    <slot v-if="$slots.footer" name="footer"></slot>

    <div class="generic-form__control">
      <button
        :class="['generic-form__submit', cssClassButton, submit.customClass]"
        :disabled="submitDisabled"
      >
        <span class="oap-button__label">
          {{ buttonLabel }}
        </span>
      </button>
    </div>

    <!-- error messages -->
    <ul
      v-show="(errorMessageList && !showToasted) || (errorMessageList && forcedShowServerMessage)"
      aria-live="polite"
      class="generic-form__error-messages"
    >
      <li v-for="(errors, $key) in errorMessageList" :key="$key" v-html="errors"></li>
    </ul>

    <!--recapctha-->
    <div v-if="hasRecaptcha" class="generic-form__recaptcha generic-form__helper-text">
      <div v-if="hasRecaptcha" class="recaptcha" v-html="recaptcha.legalText"></div>
    </div>
  </form>
</template>
<script>
import { recaptcha } from '../../../../../Foundation/Core/code/Scripts/mixins/recaptcha/recaptcha';
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts';
import eventBus from '@loreal/eventbus-js';
import GenericInput from './generic-input.vue';
import GenericOptions from './generic-options.vue';
import GenericDatepicker from './generic-datepicker.vue';
import GenericSelect from './generic-select.vue';

export default {
  name: 'GenericForm',
  mixins: [recaptcha],
  props: {
    data: {
      type: Object,
      required: true,
    },
    cssClassForm: {
      type: String,
      required: false,
      default: '',
    },
    cssClassButton: {
      type: String,
      required: false,
      default: 'button -secondary',
    },
    messageTiming: {
      type: Number,
      required: false,
      default: 5,
    },
    isSingleNotification: {
      type: Boolean,
      default: false,
    },
  },
  settings: {
    toastNotificationEventName: 'oap-toast-notification:open',
  },
  data() {
    return {
      richTextError: undefined,
      components: {
        GenericInput,
        GenericOptions,
        GenericDatepicker,
        GenericSelect,
      },
      errorMessageList: null,
      formData: {},
      isFormValid: true,
      items: [],
      typeMap: {},
      object: {},
      buttonLabel: null,
      submitDisabled: false,
      triggerErrorMessage: '',
      isFirstError: true,
    };
  },
  computed: {
    formId() {
      return this.object.formId;
    },
    recaptcha() {
      return this.object.recaptcha;
    },
    hasRecaptcha() {
      return this.object.recaptcha && this.object.recaptcha.hasRecaptcha;
    },
    submit() {
      return this.object.submit;
    },
    formAction() {
      return this.submit.apiUrl;
    },
    validatedSections: {
      get: function () {
        return this.object.sections;
      },
      set: function (nSections) {
        this.object.sections = nSections;
      },
    },
    showToasted() {
      return !this.submit.successPage;
    },
    forcedShowServerMessage() {
      return this.submit.forcedShowServerMessage;
    },
  },
  watch: {
    formData: {
      handler() {
        this.validateFormData(false);
      },
      deep: true,
    },
  },
  created() {
    this.loadTypeMap();
    this.init();
  },
  mounted() {
    /* istanbul ignore next */
    if (this.hasRecaptcha) {
      this.loadRecaptchaOnInput(this.$refs.form, this.recaptcha.siteKey);
    }
    if (this.object.forceValidation) {
      this.validateFormData(true);
    }
    eventBus.on('setHiddenFieldsVals', (data) => {
      this.checkHiddenFieldsVals(data);
    });
  },
  destroyed() {
    // here we return the initial value of the properties which will be used after re-renderin
    this.data.sections.forEach((section) => {
      section.fields.forEach((field) => {
        field.edited = false;
        field.errorMessages = [];
      });
    });
  },

  methods: {
    loadTypeMap() {
      const GENERIC_INPUT_TYPE = 'generic-input';
      const GENERIC_OPTIONS_TYPE = 'generic-options';
      const GENERIC_DATEPICKER_TYPE = 'generic-datepicker';
      const GENERIC_SELECT_TYPE = 'generic-select';

      this.typeMap = {
        ...this.typeMap,
        text: GENERIC_INPUT_TYPE,
        email: GENERIC_INPUT_TYPE,
        password: GENERIC_INPUT_TYPE,
        textarea: GENERIC_INPUT_TYPE,
        radio: GENERIC_OPTIONS_TYPE,
        checkbox: GENERIC_OPTIONS_TYPE,
        datepicker: GENERIC_DATEPICKER_TYPE,
        dropdown: GENERIC_SELECT_TYPE,
      };
    },
    init() {
      this.object = this.data;
      let fieldArray = [];

      this.object.sections.forEach((section) => {
        fieldArray = [].concat(section.fields, fieldArray);
        section.fields.forEach((field) => {
          this.fixFieldRequiredValidation(field);
        });

        if (section.subSections) {
          section.subSections.forEach((subSection) => {
            fieldArray = [].concat(subSection.fields, fieldArray);
            subSection.fields.forEach((field) => {
              this.fixFieldRequiredValidation(field);
            });
          });
        }
      });
      this.items = Array.from(fieldArray);

      //set button label
      this.updateSubmitLabel(this.submit.submitReadonly ? this.submit.submitReadonly : false);
    },
    fixFieldRequiredValidation(field) {
      this.formData[field.id] = null;

      if (field.defaultValue) {
        this.formData[field.id] = field.defaultValue;
      }

      //Get values from requiredValidation
      if (field.requiredValidation) {
        field.required = field.requiredValidation.isRequired;
        field.requiredMessage = field.requiredValidation.message;
      }

      //Get datepicker specific validation values
      if (field.type === 'datepicker') {
        field.allDatesRequired = field.allFieldsRequired.isRequired;
        field.missingDateMessage = field.allFieldsRequired.message;
      }

      //Get values from dateValidation
      if (field.dateValidation) {
        field.startYear = field.dateValidation.startYear;
        field.minAge = field.dateValidation.minAge;
        field.minAgeValidationMessage = field.dateValidation.minAgeValidationMessage;
      }
    },
    getUserIdentifier(payload) {
      const fieldIdentifier = this.items.find((field) => field.isUserId);
      const userIdFromPayload = fieldIdentifier
        ? payload.fields.find((field) => field.id == fieldIdentifier.id)
        : null;

      return userIdFromPayload ? userIdFromPayload.value : '';
    },
    async submitEvent() {
      const payload = this.formatFormData(this.formData);

      this.validateFormData(true);

      if (!this.isFormValid) {
        this.focusFirstInvalidField();
        this.handlerAnalytics(this.data.submit.eventTagging, true);
      } else {
        this.errorMessageList = [];
        this.updateSubmitLabel(true);

        if (this.hasRecaptcha) {
          payload.recaptchaToken = await this.generateToken(this.recaptcha.siteKey);
        }

        payload.userId = this.getUserIdentifier(payload);

        payload.IsGpcEnabled = false;

        if (window.OneTrust) {
          payload.IsGpcEnabled =
            navigator.globalPrivacyControl &&
            window.OneTrust.GetDomainData().Groups.some((data) => data.IsGpcEnabled);
        }

        eventBus.emit('form:submitted');

        /* istanbul ignore next */
        fetch(this.formAction, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json;charset=utf-8' },
          credentials: 'same-origin',
          body: JSON.stringify(payload),
        })
          .then(this.parseJSON)
          .then((data) => {
            if (data.success) {
              eventBus.emit('form:success');
              this.handlerAnalytics(this.data.submit.eventTagging);
              this.updateSubmitLabel(false);
              this.handleCCPAReturn(data.info);
              this.handleSuccess(data.redirectUrl);

              if (this.showToasted) {
                eventBus.emit(this.$options.settings.toastNotificationEventName, {
                  type: 'success',
                  message: this.data.submit.successMessage,
                  cta: this.data.submit.successCta,
                });
              }

              return;
            }
            this.handleValidationErrorMessages(data);
            this.updateSubmitLabel(false);
            if (!data.success && this.showToasted) {
              eventBus.emit(this.$options.settings.toastNotificationEventName, {
                type: 'error',
                message: this.data.submit.errorMessage,
                cta: this.data.submit.errorCta,
              });
            }
          })
          .catch((error) => {
            console.error('Could not load answer', error); // eslint-disable-line no-console
            this.submitErrorMessages();
            this.updateSubmitLabel(false);
            if (this.showToasted) {
              eventBus.emit(this.$options.settings.toastNotificationEventName, {
                type: 'error',
                message: this.data.submit.errorMessage,
                cta: this.data.submit.errorCta,
              });
            }
          });
      }
    },
    submitErrorMessages() {
      this.errorMessageList = [];
      this.errorMessageList.push(this.submit.errorMessage);
    },
    handleValidationErrorMessages(data) {
      const hasValidationErrors = data.validationMessages && data.validationMessages.length > 0;

      if (hasValidationErrors) {
        this.errorMessageList = [];

        data.validationMessages.forEach((msg) => {
          this.errorMessageList.push(msg);
        });
      } else {
        this.submitErrorMessages();
      }
    },
    formatFormData(formData) {
      const mappedFields = [];

      Object.keys(formData).forEach((key) => mappedFields.push({ id: key, value: formData[key] }));

      return {
        id: this.object.formId,
        sitecoreContextItemId: this.object.sitecoreContextItemId,
        fields: mappedFields,
      };
    },
    parseJSON(response) {
      return response.json();
    },
    handleSuccess(redirectPage) {
      if (this.submit.successPage) {
        window.location.href = redirectPage ? redirectPage : this.submit.successPage;
      }
    },
    validateFormData(forceErrorVerification) {
      if (!this.items) {
        return;
      }
      this.isFirstError = true;
      //reset form valid value
      this.isFormValid = true;
      //iterate all items
      this.items.forEach((item) => {
        this.validateFormItem(item, forceErrorVerification);
      });
    },
    errorsBasedOnRegex(itemValue, item) {
      if (!(item.validations && itemValue)) {
        return [];
      }

      const errorMessages = [];
      item.validations.forEach((validation) => {
        const regexp = new RegExp(validation.regex);
        if (!itemValue.match(regexp)) {
          errorMessages.push(validation.message);
        }
      });

      return errorMessages.length > 0 ? errorMessages : [];
    },
    errorsBasedOnEquality(itemValue, item) {
      const hasEqualityCheck =
        item.equalsValidation &&
        item.equalsValidation['equalsTo'] &&
        item.equalsValidation['message'] &&
        item.equalsValidation['message'].length > 0;

      if (!hasEqualityCheck) {
        return [];
      }

      const referenceFieldId = item.equalsValidation['equalsTo'];
      const referenceFieldValue = this.formData[referenceFieldId];
      const valid = itemValue === referenceFieldValue;

      return referenceFieldValue && !valid ? item.equalsValidation['message'] : [];
    },
    dateStringToObject(hasDateSelected, itemValue) {
      if (!hasDateSelected) {
        return false;
      }
      const dateValues = itemValue.split('/');
      const month = parseInt(dateValues[0]),
        day = parseInt(dateValues[1]),
        year = parseInt(dateValues[2]);
      return { day: day, month: month, year: year };
    },
    errorsForDatepicker(itemValue, item) {
      if (item.type !== 'datepicker') {
        return [];
      }

      const errorMessages = [];
      const date = itemValue;
      const validInputValue = (itemValue) => {
        if (itemValue === null) return false;
        if (typeof itemValue === 'string') return true;
      };
      const allDatesRequired =
        item.allDatesRequired &&
        item.missingDateMessage &&
        item.pristine === false &&
        !validInputValue(date);
      const isRequired =
        item.required && item.requiredMessage && item.edited && !validInputValue(date);
      const hasAgeErrorVerification =
        validInputValue(date) && item.minAge && item.minAge > 0 && item.minAgeValidationMessage;

      if (isRequired) {
        errorMessages.push(item.requiredMessage);
      }

      if (allDatesRequired) {
        errorMessages.push(item.missingDateMessage);
      }

      if (hasAgeErrorVerification) {
        const result = date.match(/[0-9]{2}([\/])[0-9]{2}[\/][0-9]{4}/);
        let dateSplitted = {};
        if (result) {
          const split = result[0].split(result[1]);
          dateSplitted.day = +split[1];
          dateSplitted.month = +split[0];
          dateSplitted.year = +split[2];
        }
        const calculatedYear = dateSplitted.year + item.minAge;
        const calculatedMonth = dateSplitted.month - 1;
        const currentDate = new Date();
        const selectedDate = new Date(calculatedYear, calculatedMonth, dateSplitted.day);

        if (currentDate.getTime() < selectedDate.getTime()) {
          errorMessages.push(item.minAgeValidationMessage);
        }
      }

      return errorMessages;
    },
    errorsForRequiredFields(itemValue, item) {
      if (item.type === 'datepicker') {
        return [];
      }

      const hasRequiredMessage = item.required && item.requiredMessage;
      const isEmptyOrNull = !itemValue || itemValue.length === 0;

      return item.edited && hasRequiredMessage && isEmptyOrNull ? item.requiredMessage : [];
    },
    resetErrors(fieldItem) {
      if (!fieldItem.errorMessages.length) return;
      this.items.forEach((item) => {
        if (item.id !== fieldItem.id) return;
        fieldItem.errorMessages.splice(0);
      });
    },
    validateFormItem(item, forceErrorVerification) {
      const itemValue = this.formData[item.id];

      //force error verification
      if (!item.edited) {
        item.edited = forceErrorVerification;
      }

      const errorMessages = [].concat(
        this.errorsBasedOnRegex(itemValue, item),
        this.errorsBasedOnEquality(itemValue, item),
        this.errorsForDatepicker(itemValue, item),
        this.errorsForRequiredFields(itemValue, item)
      );

      item.errorMessages = errorMessages;

      if (errorMessages && errorMessages.length) {
        this.isFormValid = false;
        if (this.isFirstError) {
          this.triggerErrorMessage = errorMessages;
          this.isFirstError = false;
        }
      }
    },
    async focusFirstInvalidField() {
      this.$nextTick(() => {
        const firstInvalidField = this.$refs.form.querySelectorAll('[aria-invalid="true"]')[0];

        if (firstInvalidField) {
          firstInvalidField.focus();
        }
      });
    },
    updateSubmitLabel(start) {
      this.buttonLabel = this.submit.submitReadonly
        ? this.submit.label
        : start && this.submit.requestLabel
        ? this.submit.requestLabel
        : this.submit.label;
      this.submitDisabled = start;
    },
    handleCCPAReturn(info) {
      if (this.isValidCcpaConsent(info)) {
        var content = JSON.parse(info.ccpa);
        content.forEach((consent) => {
          let values = consent.value.split(',');
          values.forEach((val) => {
            window.OneTrust.UpdateConsent(consent.key, val);
          });
        });
      }
    },
    isValidCcpaConsent(info) {
      return window.OneTrust && window.OneTrust.UpdateConsent && info && info.ccpa;
    },

    handlerAnalytics(eventTagging, formError = false) {
      if (!eventTagging) {
        console.warn(`Form Event Tagging: Tagging object missing.`);
        return;
      }

      const hasMissingProperties = ['category', 'action', 'label'].some(
        (prop) => !Object.hasOwn(eventTagging, prop)
      );

      if (hasMissingProperties) {
        console.warn(`Form Event Tagging: Some tagging properties are missing.`);
        return;
      }

      const dataToSend = {
        type: 'userActionEvent',
        ecommerce: eventTagging.ecommerce ? eventTagging.ecommerce : 'undefined',
        category: eventTagging.category,
        action: eventTagging.action,
        label: eventTagging.label,
        requestType: eventTagging.requesttype,
        event_name: eventTagging.name ? eventTagging.name : null,
        referral: eventTagging.referral ? eventTagging.referral : null,
      };

      if (formError) {
        dataToSend.label = this.triggerErrorMessage.length
          ? this.triggerErrorMessage[0]
          : 'The field is required';
        delete dataToSend.requestType;
      }

      AnalyticsHandler.getAnalyticsHandler().push(dataToSend);
    },
    touchInput(field) {
      field.edited = true;
      field.pristine = false;
    },
    checkHiddenFieldsVals(data) {
      let currentForm = this.formData;
      let dataField = Object.values(data)[0];
      const hasKey =
        Object.keys(currentForm).length &&
        Object.prototype.hasOwnProperty.call(currentForm, dataField.id);
      if (hasKey) {
        this.setHiddenFieldsVals(data);
      }
    },
    setHiddenFieldsVals(data) {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(data)) {
        this.formData[value.id] = value.value;
      }
    },
  },
};
</script>
