<template>
  <div class="oap-beauty-genius oap-beauty-genius--in-grid" :style="{ order: position }">
    <picture class="oap-beauty-genius__background">
      <img class="lazyload" alt="" src="" :data-src="item.image.default.src" />
    </picture>
    <div class="oap-beauty-genius__content">
      <div class="oap-beauty-genius__intro">
        <div class="oap-beauty-genius__logo">
          <img :src="item.logo.src" :alt="item.logo.alt" />
        </div>
        <div class="oap-beauty-genius__copy">
          <div class="oap-beauty-genius__header">
            {{ item.title }}
          </div>
          <div class="oap-beauty-genius__description">
            {{ item.description }}
          </div>
        </div>
        <div class="oap-beauty-genius__questions">
          <button
            class="oap-beauty-genius__cta"
            v-bus.click="'beauty-genius-iframe:open'"
            v-tag:useractionevent="item.analytics"
          >
            <span class="oap-beauty-genius__label">
              {{ item.cta.label }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tag } from '@Foundation/analyticsHandler/directives/tag';

export default {
  name: 'OapBeautyGeniusCard',
  directives: {
    tag,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    position: {
      type: Number,
      default: 1,
    },
  },
};
</script>
